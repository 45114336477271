import { CardAvisos } from "./card";
import styles from "../../../../styles/module/transparency/sacop.module.css";
import { useQuery } from "react-query";
import api from "../../../../services/api";
import Loading from "../../others/Loading";
import Carousel from "react-bootstrap/Carousel";
import SubTitle from "../../others/title/SubTitle";

export default function AvisosHome() {
  const { data, isLoading, isError } = useQuery(
    "avisos",
    async () => {
      const response = await api.get("/v2/sacop/list/all/avisos?pageSize=10");
      return response.data.res;
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutos
      cacheTime: 10 * 60 * 1000, // 10 minutos
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <p>Erro ao carregar os avisos. Tente novamente mais tarde.</p>;
  }

  if (!data || !data.length) {
    return <p>Nenhum aviso de licitação disponível no momento.</p>;
  }

  return (
    <section className={styles.container}>
      <SubTitle subTitle={"Avisos de Licitações"} border={true} />
      <div className={styles.box}>
        <Carousel controls={false} className={styles.container_avisos_home}>
          {data.map((item, index) => (
            <Carousel.Item key={`${item.ID}-${index}`}>
              <CardAvisos data={item} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
}
